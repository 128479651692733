import { FC } from "react";
import Swiper, { Slider } from "@/components/Swiper";
import Typography from "@/components/Typography";
import Card from "@/components/Card";
import { Skeleton } from "@/components/Loader";
import Grid from "@/components/Grid";
import { ResourceCard, ResourceItem, BlogCard } from "@/components/Resources";

import { useResponsive } from "@/hooks";
import {
  useResourcesQuery,
  useBlogsQuery,
  RESOURCE_CATEGORIES,
  Blog,
} from "@/fetch/gworld";
import styles from "./ResourceCentreTab.module.scss";

const ResourceCentreTab: FC = () => {
  const { isMobile } = useResponsive();
  const { data: pdgResourcesData, isLoading: isLoadingPdgResources } =
    useResourcesQuery(RESOURCE_CATEGORIES.PDG);
  const { data: visaResourcesData, isLoading: isLoadingVisaResources } =
    useResourcesQuery(RESOURCE_CATEGORIES.Visa);
  const { data: guidesResourcesData, isLoading: isLoadingGuidesResources } =
    useResourcesQuery(RESOURCE_CATEGORIES.Guides);
  const {
    data: postArrivalResourcesData,
    isLoading: isLoadingPostArrivalResources,
  } = useResourcesQuery(RESOURCE_CATEGORIES.PostArrivalDocuments);
  const { data: blogsData, isLoading: isLoadingBlogs } = useBlogsQuery();

  const blogs = blogsData?.result || [];

  const pdgResource = pdgResourcesData?.result?.[0];

  const isLoadingExtraResources =
    isLoadingGuidesResources ||
    isLoadingPostArrivalResources ||
    isLoadingVisaResources;

  const visaResources = (visaResourcesData?.result || []).filter(
    (each) => each.id !== pdgResource?.id
  );
  const guideResources = (guidesResourcesData?.result || []).filter(
    (each) => each.id !== pdgResource?.id
  );
  const postArrivalResources = (postArrivalResourcesData?.result || []).filter(
    (each) => each.id !== pdgResource?.id
  );

  const extraResources = [
    ...visaResources.map((each, idx) => ({
      resource: each,
      type: RESOURCE_CATEGORIES.Visa,
      index: idx,
    })),
    ...guideResources.map((each, idx) => ({
      resource: each,
      type: RESOURCE_CATEGORIES.Guides,
      index: idx,
    })),
    ...postArrivalResources.map((each, idx) => ({
      resource: each,
      type: RESOURCE_CATEGORIES.PostArrivalDocuments,
      index: idx,
    })),
  ];

  const isEmpty =
    !isLoadingExtraResources &&
    !isLoadingPdgResources &&
    extraResources.length === 0 &&
    !Boolean(pdgResource);

  // remove before going live

  const tempBlogs = [
    {
      title: "Essential Tips to Travel Solo and Love It.",
      thumbnail:
        "https://blog.globalworkandtravel.com/content/images/size/w1200/2022/07/holly-mandarich-UVyOfX3v0Ls-unsplash.jpg",
      marketing: {
        url: "https://blog.globalworkandtravel.com/essential-tips-to-travel-solo-and-love-it/",
      },
    },
    // {
    //   title: "Top 5 photography tips for beginners!",
    //   thumbnail:
    //     "https://blog.globalworkandtravel.com/content/images/size/w1200/2022/02/5.Havefunb.jpg",
    //   marketing: {
    //     url: "https://blog.globalworkandtravel.com/top-5-photography-tips-for-beginners/",
    //   },
    // },
    {
      title: "The Complete A-Z of Gap Year Travels",
      thumbnail:
        "https://dvna932ue33yp.cloudfront.net/blog-migration/hubfs/474541/gap-year-travels-3.jpg",
      marketing: {
        url: "https://blog.globalworkandtravel.com/the-complete-a-z-of-gap-year-travels/",
      },
    },
  ] as Array<Blog>;

  return (
    <div>
      <Typography variant="h6">Resource Centre</Typography>
      {isEmpty && (
        <div className={styles.emptyContainer}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src="/images/empty-resources-result.svg" alt="icon" />
          <Typography variant="h6" className={styles.emptyTitle}>
            Prepare for your adventure!
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Looks like there&apos;s no documents assigned for this trip yet.
            <br />
            Stay tuned for updates to ensure you&apos;re fully equipped for your
            journey.
          </Typography>
        </div>
      )}
      {isLoadingExtraResources || isLoadingPdgResources ? (
        <Skeleton variant="text" width={500} />
      ) : (
        <>
          {(extraResources.length > 0 || Boolean(pdgResource)) && (
            <Typography color="text.secondary">
              Prepare for the adventure of a lifetime with these exclusive
              resources, carefully curated just for you.
            </Typography>
          )}
        </>
      )}
      <div className={styles.root}>
        {!isEmpty && (
          <Card className={styles.rootCard}>
            {isLoadingPdgResources ? (
              <Skeleton variant="text" width={250} />
            ) : (
              <>
                {pdgResource && (
                  <Typography
                    color="text.secondary"
                    className={styles.cardTitle}
                  >
                    {pdgResource.category === RESOURCE_CATEGORIES.PDG
                      ? "Pre-Departure Guide"
                      : "Featured Document"}
                  </Typography>
                )}
              </>
            )}
            {isLoadingPdgResources ? (
              <Skeleton
                variant="rectangular"
                className={styles.pdgLoading}
                width={627}
                height={150}
              />
            ) : (
              <>
                {pdgResource && (
                  <ResourceCard
                    resource={pdgResource}
                    type={pdgResource.category || RESOURCE_CATEGORIES.PDG}
                  />
                )}
              </>
            )}

            {isLoadingExtraResources ? (
              <Skeleton
                variant="text"
                width={250}
                className={styles.extraResourceTitleLoading}
              />
            ) : (
              <>
                {Boolean(extraResources.length) && (
                  <Typography
                    color="text.secondary"
                    className={styles.cardSubTitle}
                  >
                    {"Related to your trip"}
                  </Typography>
                )}
              </>
            )}

            <Grid container spacing="16px">
              {isLoadingExtraResources
                ? [1, 2].map((each) => (
                    <Grid item md={6} xs={12} key={each}>
                      <Skeleton
                        variant="rectangular"
                        width={305}
                        height={104}
                      />
                    </Grid>
                  ))
                : extraResources.map((each) => (
                    <Grid item md={6} xs={12} key={each.resource.title}>
                      <ResourceItem
                        resource={each.resource}
                        type={each.type}
                        index={each.index}
                      />
                    </Grid>
                  ))}
            </Grid>
          </Card>
        )}
        {!isLoadingBlogs && blogs.length > 0 && (
          <>
            {!isMobile ? (
              <div className={styles.blogContainer}>
                {isLoadingBlogs ? (
                  <>
                    <Skeleton
                      variant="rectangular"
                      className={styles.blogLoading}
                    />
                    <Skeleton
                      variant="rectangular"
                      className={styles.blogLoading}
                    />
                  </>
                ) : (
                  <Swiper
                    slidesPerPage={2}
                    spaceBetween={16}
                    title="Explore Global Roaming"
                    className={styles.swiper}
                    hideSwipeButton={tempBlogs.length < 3}
                  >
                    {tempBlogs.map((each) => (
                      <Slider key={each.id}>
                        <BlogCard blog={each} />
                      </Slider>
                    ))}
                  </Swiper>
                )}
              </div>
            ) : (
              <div className={styles.mobileBlogContainer}>
                <Typography variant="h6" className={styles.blogTitle}>
                  Explore Global Roaming
                </Typography>
                {isLoadingBlogs ? (
                  <>
                    <Skeleton
                      className={styles.mobileBlogLoading}
                      variant="rectangular"
                    />
                    <Skeleton
                      className={styles.mobileBlogLoading}
                      variant="rectangular"
                    />
                  </>
                ) : (
                  tempBlogs.map((each) => (
                    <BlogCard
                      key={each.id}
                      blog={each}
                      className={styles.blogCard}
                    />
                  ))
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ResourceCentreTab;
